import React, { memo, useEffect, useState, useRef } from 'react';
import './info-tab.css'
import ToggleHide from '../../assets/toggle-hide.svg';
import ToggleShow from '../../assets/toggle-show.svg';
import FunctionTab from './tabs/function-tab';
import SpecsAndDetail from './tabs/specs-detail-tab';
import KeyFeatures from './tabs/key-features-tab';
import Downloads from './tabs/downloads-tab';
import Warranty from './tabs/warranty-tab';
import { InfoTabContext, useInfoTabContext } from '../../context/infotab-context';

interface IToggleInfoTab {
    tabName: string;
    children: React.ReactNode;
    tabType: string;
    tabAnchor: string;
}

const listInfoTabKey: string[] = ['specsDetails', 'function', 'faq', 'warranty'];
const convertToAAListInfo = {
    overview: 'specsDetails',
    accessories: 'function',
    downloads: 'faq',
    applications: 'warranty',
    technicalData: 'keyFeatures'
}

const InfoTabDesktop: React.FC = memo(() => {
    const infoTabRef = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState(() => {
        const hash = window?.location?.hash?.slice(1);

        // @ts-ignore
        return convertToAAListInfo?.[hash || 'overview'];
    });
    const { product } = useInfoTabContext();

    useEffect(() => {
        const hash = window?.location?.hash?.slice(1);

        //@ts-ignore
        if (!hash) {
            for (let index = 0; index < listInfoTabKey.length; index++) {
                if (product?.[0]?.fields?.[listInfoTabKey[index]]) {
                    setActiveTab(listInfoTabKey[index]);
                    break;
                }
                continue;
            }
        }
    }, [product]);

    useEffect(() => {
        //@ts-ignore
        setActiveTab((prev: string) => convertToAAListInfo?.[window?.location?.hash?.slice(1) || ''] || prev);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window?.location?.hash]);


    useEffect(() => {
        const element = infoTabRef.current;
        let timeoutId: any | undefined = undefined;

        if (element && window?.location?.hash.slice(1)) {
            timeoutId = setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }

        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoTabRef.current]);

    if (activeTab && !product?.[0]?.fields?.[activeTab]) return null;

    return (
        <>
            <div ref={infoTabRef} className='info-tab flex flex-row justify-center gap-16 capitalize items-center font-inter relative  h-10 transition-all cursor-pointer text-white bg-black'>
                {product?.[0]?.fields?.specsDetails && <a
                    className={`${activeTab === 'specsDetails' ? 'active' : ''} px-2.5 h-full flex items-center`}
                    onClick={() => setActiveTab('specsDetails')}
                    href='#overview'
                >
                    Overview
                </a>}
                {product?.[0]?.fields?.keyFeatures && <a
                    className={`${activeTab === 'keyFeatures' ? 'active' : ''} px-2.5 h-full flex items-center`}
                    onClick={() => setActiveTab('keyFeatures')}
                    href='#technicalData'
                >
                    Technical data
                </a>}
                {product?.[0]?.fields?.function && <a
                    className={`${activeTab === 'function' ? 'active' : ''} px-2.5 h-full flex items-center`}
                    onClick={() => setActiveTab('function')}
                    href='#accessories'
                >
                    Accessories
                </a>}
                {product?.[0]?.fields?.faq && <a
                    className={`${activeTab === 'faq' ? 'active' : ''} px-2.5 h-full flex items-center`}
                    onClick={() => setActiveTab('faq')}
                    href='#downloads'
                >
                    Downloads
                </a>}
                {product?.[0]?.fields?.warranty && <a
                    className={`${activeTab === 'warranty' ? 'active' : ''} px-2.5 h-full flex items-center`}
                    onClick={() => setActiveTab('warranty')}
                    href='#applications'
                >
                    Applications
                </a>}
            </div>
            <div className='h-auto render-tab transition-all'>
                <div className={activeTab === 'specsDetails' ? 'active' : 'hidden'}>
                    <SpecsAndDetail />
                </div>
                <div className={activeTab === 'keyFeatures' ? 'active' : 'hidden'}>
                    <KeyFeatures />
                </div>
                <div className={activeTab === 'function' ? 'active' : 'hidden'}>
                    <FunctionTab />
                </div>
                <div className={activeTab === 'warranty' ? 'active' : 'hidden'}>
                    <Warranty />
                </div>
                <div className={activeTab === 'faq' ? 'active' : 'hidden'}>
                    <Downloads />
                </div>
            </div>
        </>
    )
});

const ToggleInfoTab: React.FC<IToggleInfoTab> = memo(({ children, tabName, tabType, tabAnchor }) => {

    const [isActive, setIsActive] = useState(false);

    const toggleQuestion = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        //@ts-ignore
        setIsActive(convertToAAListInfo?.[window?.location?.hash?.slice(1) || ''] === tabType);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window?.location?.hash]);

    useEffect(() => {
        const element = document.querySelector('.info-tab');
        let timeoutId: any | undefined = undefined;
        if (element && window?.location?.hash.slice(1)) {
            timeoutId = setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div className={`detail-box ${isActive ? 'active' : ''} border-t`}>
                    <a
                        className={`py-4 flex justify-between  font-bold text-18`}
                        onClick={toggleQuestion}
                        href={`#${tabAnchor}`}
                    >
                        {tabName}
                        <div className='inline-block my-auto md:hidden'>
                            <img className={`min-w-[8px] min-h-[8px] ${isActive ? 'hidden' : 'block'}`} src={ToggleShow} alt='m' />
                            <img className={`min-w-[8px] min-h-[8px] ${isActive ? 'block' : 'hidden'}`} src={ToggleHide} alt='m' />
                        </div>
                    </a>
                    <div className={isActive ? 'open-box' : 'close-box'}>
                        {children}
                    </div>
                </div>
    );
});

const InfoTabMobile: React.FC = memo(() => {
    const { product } = useInfoTabContext();

    return (
      <>
        <div className='info-tab border-y md:border-0 flex flex-col md:flex-row justify-center md:gap-16 font-inter relative md:h-10 transition-all'>
            <div className='lg:border-b absolute w-[100vw] bottom-0'></div>
            {product?.[0]?.fields?.specsDetails && <ToggleInfoTab tabName='Overview' tabType='specsDetails' tabAnchor='overview'>
                <SpecsAndDetail />
            </ToggleInfoTab>}

            {product?.[0]?.fields?.keyFeatures && <ToggleInfoTab tabName='Technical Data' tabType='keyFeatures' tabAnchor='technicalData'>
                <KeyFeatures />
            </ToggleInfoTab>}

            {product?.[0]?.fields?.function && <ToggleInfoTab tabName='Accessories' tabType='function' tabAnchor='accessories'>
                <FunctionTab />
            </ToggleInfoTab>}

            {product?.[0]?.fields?.faq && <ToggleInfoTab tabName='Downloads' tabType='faq' tabAnchor='downloads'>
                <Downloads />
            </ToggleInfoTab>}

            {product?.[0]?.fields?.warranty && <ToggleInfoTab tabName='Applications' tabType='warranty' tabAnchor='applications'>
                <Warranty />
            </ToggleInfoTab>}
        </div>
      </>
    );
});

interface IInfoTab {
    product: any;
}

const InfoTab: React.FC<IInfoTab> = ({ product }) => {

    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        setIsMobile(window?.innerWidth <= 768);
        const handleResize = () => {
            setIsMobile(window?.innerWidth <= 768);
        };
        window?.addEventListener('resize', handleResize);
        return () => window?.removeEventListener('resize', handleResize);
    }, []);

    return (
        <InfoTabContext.Provider value={{ product }}>
            <div className='relative'>
                { isMobile ?
                <InfoTabMobile />
                :
                <InfoTabDesktop />
                }
            </div>
        </InfoTabContext.Provider>

    );
};

export default InfoTab;
