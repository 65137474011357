import React, { memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import AlignLogo from '../assets/align-array-logo.png';
import HeaderTopBarPromotion from '../components/top-bar-promotion';
import { useOnClickOutside } from '../custom-hooks/useClickOutside';
import FooterIcon from './footer-icon';
import { IAppContextStore, useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';

interface IMenuLink {
  text: string;
  path: string;
  isExternal?: boolean;
}

const headerLinks: IMenuLink[] = [
  {
    text: 'Home',
    path: '/'
  },
  {
    text: 'About us',
    path: '/about-us'
  },

  {
    text: 'Products',
    path: '/products'
  },
  {
    text: 'FAQ',
    path: '/faq',
  },

];

const MenuLink: React.FC<{ item: IMenuLink, isActive: boolean, onClick: () => void }> = ({ item, isActive, onClick }) => (
  <div className='w-full lg:w-20  border-b md:border-none py-5 border-[#BABACE] lg:border-transparent hover:border-jm-black lg:hover:scale-125 transition-all'>
    {
      item.isExternal === true ? (
        <a className='font-bold lg:font-normal cursor-pointer' href={item.path} target='_blank' rel='noreferrer'>{item.text}</a>
      ) : (
        <a
          href={item.path} 
          onClick={(e) => {e.preventDefault(); e.stopPropagation(); onClick();}}
          className={`text-18 font-normal cursor-pointer ${isActive ? 'lg:font-bold' : ''}`}>
          {item.text}
        </a>
      )
    }
  </div>
);

const Header: React.FC = () => {
  const { rawContentFul }: IAppContextStore = useAppContext();
  const heroData = useMemo(() => filterItems(rawContentFul, 'homePageHero')?.[0], [rawContentFul]);
  const footerInfo = useMemo(() => filterItems(rawContentFul, 'footerSection')?.[0], [rawContentFul]);
  const [openNav, setOpenNav] = useState(false);
  const [openLogoMenu, setOpenLogoMenu] = useState(false);
  const [showMobileSearchInput, setShowMobileSearchInput] = useState(false);
  const menuArea: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const location = useLocation();
  const navigate = useNavigate();
  const currentMenuIndex = useMemo(() => {
    if (location.pathname === '/') {
      return 0;
    }

    return _findIndex(headerLinks, (item: IMenuLink) => location.pathname.startsWith(item.path), 1);
  }, [location]);
  //@ts-ignore
  const socialLink: any = useMemo(() => footerInfo?.fields?.socialLink, [footerInfo]);

  useOnClickOutside(menuArea, () => setOpenNav(false));

  useEffect(() => {
    if (openNav === true) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, [openNav]);

  const onClickAMenuLink = useCallback((link: IMenuLink) => navigate(link.path), [navigate]);
  const searchInputMobileRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const searchInputMobile: RefObject<HTMLInputElement> = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;
  const menuWrapper: RefObject<HTMLInputElement> = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;
  useOnClickOutside(searchInputMobileRef, () => setShowMobileSearchInput(false));
  useOnClickOutside(menuWrapper, () => setOpenLogoMenu(false));

  useEffect(() => {
    if (showMobileSearchInput === true) {
      searchInputMobile.current?.focus();
    }
  }, [showMobileSearchInput, searchInputMobile]);

  return (
    <>
      <HeaderTopBarPromotion />
      <div ref={searchInputMobileRef} className={`w-full max-w-container relative bg-white flex justify-between p-4 items-center border-b lg:border-0 ${currentMenuIndex === 0 ? 'lg:border-none' : 'border-[#BABACE]'}`}>
        <div ref={menuWrapper} className='relative z-10'>
          <div className='absolute shadow-overlay w-full h-16 lg:h-28 rounded-2xl -z-10 top-6 transition-all' style={
              openLogoMenu ? { boxShadow: '0px 1px 42px', scale: '1' } : { boxShadow: '0px 0px 0px', scale: '0.1' }
            }></div>
          <div className='flex items-center bg-white px-3 sm:px-4 py-2 rounded-t-xl relative -ml-4 xl:-ml-8'>
            <div className='cursor-pointer z-10' onClick={() => navigate('/')}>
              <img className='object-cover object-center h-[30px] md:h-11 lg:h-14' src={AlignLogo} alt='Align Array' />
            </div>
            <div className='w-[20px] ml-2 cursor-pointer' onClick={() => setOpenLogoMenu(!openLogoMenu)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"><path className='fill-black' d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
            </div>
          </div>
          <div
          className={`transition-all absolute md:-bottom-7 lg:-bottom-8 py-2 -ml-4 xl:-ml-8 border-[#BABACE] flex flex-row bg-white p-4 rounded-b-xl ${
            openLogoMenu ? 'pr-7 border-t block' : 'pr-0 border-none'
            } ${openLogoMenu ? 'translate-y-0 scale-100 md:translate-y-8 lg:translate-y-10' : '-translate-y-10 scale-50'}`}>
              <div className='cursor-pointer' onClick={() => window.location.href = (heroData?.fields?.soundToolsLogoUrl as string || '/')}>
                <img className='object-contain object-center h-[30px] md:h-11 lg:h-14' src={Logo} alt='SoundTool' />
              </div>
              <div className='w-5'></div>
            </div>
        </div>
        
        <div className={`hidden lg:flex items-center gap-10 text-center text-16 ${currentMenuIndex === 0 ? 'text-black' : ''}`} >
          {
            _map(headerLinks, (item: IMenuLink, index: number) => (
              <MenuLink
                key={index}
                item={item}
                isActive={index === currentMenuIndex} onClick={() => onClickAMenuLink(item)} />
            ))
          }
            <a target='_blank' href={heroData?.fields?.orderButtonUrl as string || '/products'} rel="noreferrer" className='text-center bg-aquamarine text-black hover:bg-black/[0.7] transition-all rounded-[20px] leading-10 w-28 h-10'>Order</a>
        </div>
        <div className='lg:hidden cursor-pointer' onClick={() => setOpenNav(!openNav)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24.534" height="17.356" viewBox="0 0 24.534 17.356">
            <g transform="translate(-3 -7.5)">
              <path id="Path_100" data-name="Path 100" d="M4.5,18H26.034" transform="translate(0 -1.822)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              <path id="Path_101" data-name="Path 101" d="M4.5,9H26.034" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              <path id="Path_102" data-name="Path 102" d="M4.5,27H26.034" transform="translate(0 -3.644)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            </g>
          </svg>
        </div>
      </div>
      
      <div className={`transition-all z-10 duration-500 fixed top-0 left-0 bg-jm-slide-background h-screen bg-opacity-60 opacity-100 w-full ${openNav ? 'translate-x-0' : '-translate-x-full'}`}>
        <div
          ref={menuArea}
          className='w-9/12 sm:w-4/12 bg-jm-white opacity-100 h-screen overflow-hidden flex flex-col text-left justify-between'>
          <div className='px-4 py-5 flex flex-col'>
            <div className='flex justify-end mb-4' onClick={() => setOpenNav(!openNav)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <path id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm3.705,19.92L18,19.589l-3.705,3.705a1.124,1.124,0,1,1-1.589-1.589L16.411,18l-3.705-3.705a1.124,1.124,0,0,1,1.589-1.589L18,16.411l3.705-3.705a1.124,1.124,0,1,1,1.589,1.589L19.589,18l3.705,3.705a1.129,1.129,0,0,1,0,1.589A1.116,1.116,0,0,1,21.705,23.295Z" transform="translate(-3.375 -3.375)"/>
              </svg>
            </div>
          {
            _map(headerLinks, (item: IMenuLink, index: number) => (
              <MenuLink key={index} item={item} isActive={index === currentMenuIndex}  onClick={() => {
                setOpenNav(false);
                onClickAMenuLink(item)
              }}  />
            ))
          }
            <a className='mt-4 text-center bg-aquamarine text-black hover:bg-black/[0.7] transition-all h-10 rounded-[20px] leading-10 w-full' target='_blank' href={heroData?.fields?.orderButtonUrl as string || '/products'} rel="noreferrer">Order</a>
          </div>
          <div className='bg-[#F4F4F8] flex flex-col pt-6 pl-4 pb-16 text-14'>
            <p className='font-inter text-18 pb-4'>Follow us</p>
            <div className='flex flex-row space-x-2'>
              <FooterIcon links={[
                socialLink?.facebook || 'https://www.facebook.com/',
                socialLink?.x || 'https://x.com/ratsoundtools',
                socialLink?.instagram || 'https://www.instagram.com/ratsoundtools/?hl=en',
                socialLink?.youtube || 'https://www.youtube.com/c/soundtoolschannel',
              ]} 
              color='#BABACE'
              width='38'
              height='38'
              />
            </div>
            {/* <div>{footerInfo?.fields?.copyright || `Copyright © ${new Date().getFullYear()} SoundTools. All Rights Reserved.`}</div> */}
          </div>
        </div>
      </div>
    </>
  )
};

export default memo(Header);
